<template>
  <Toast position="top-right" width="400" />
  <div class="
        surface-0
        flex
        align-items-center
        justify-content-center
        min-h-screen min-w-screen
        overflow-hidden
      ">
    <div class="grid justify-content-center p-2 lg:p-0" style="min-width: 60%">
      <div class="col-12 xl:col-6" style="
            border-radius: 56px;
            padding: 0.3rem;
            background: linear-gradient(
              180deg,
              var(--primary-color),
              rgba(33, 150, 243, 0) 30%
            );
          ">
        <div class="h-full w-full m-0 py-7 px-4" style="
              border-radius: 53px;
              background: linear-gradient(
                180deg,
                var(--surface-50) 38.9%,
                var(--surface-0)
              );
            ">
          <div class="text-center mb-5">
            <div class="text-900 text-3xl font-medium mb-3">Workflow Compartilhamento</div>
            <span class="text-600 font-medium">Cadastre sua nova senha</span>
          </div>

          <div class="w-full md:w-10 mx-auto">

            <Password id="password1" v-model="senha" placeholder="Nova Senha" class="w-full mb-3" inputClass="w-full"
              inputStyle="padding:1rem"></Password>

            <div class="flex align-items-center justify-content-between mb-5">
              <a class="font-medium no-underline ml-2 text-right cursor-pointer" style="color: var(--primary-color)"
                @click="this.$router.push('/')">Voltar</a>
            </div>
            <Button label="Salvar" class="w-full p-3 text-xl" @click="recuperar()"></Button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      hash: this.$route.params.hash,
      recuperarSenha: false,
      recuperar_email: "",
      email: "",
      password: "",
      senha: "",
      checked: false,
    };
  },
  computed: {
    logoColor() {
      if (this.$appState.darkTheme) return "white";
      return "dark";
    },
  },
  mounted() {
    this.validarHash();
  },
  methods: {
    validarHash() {
      let self = this;
      let data = {
        hash: this.hash
      };

      this.axios
        .post("/usuarios/validar/hash", data)
        .then(() => {
          console.log('Hash válida')
        })
        .catch(() => {
          self.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: 'Código expirado ou não válido!',
            life: 3000,
          });

          setTimeout(function () {
            this.$router.push("/");
          }.bind(this), 3000)
        });
    },

    recuperar() {
      let self = this;
      let data = {
        hash: this.hash,
        senha: this.senha
      };
      this.axios
        .post("/usuarios/alterar", data)
        .then((response) => {
          self.$toast.add({
            severity: "success",
            summary: "Scuesso!",
            detail: response.data.msg,
            life: 5000,
            posistion: 'top-left'
          });


          setTimeout(function () {
            this.$router.push("/");
          }.bind(this), 3000)
          this.recuperarSenha = false
        })
        .catch(() => {
          self.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Código expirado ou não válido!",
            life: 3000,
          });
        });
    },


    login() {
      let self = this;
      let data = {
        password: this.password,
        email: this.email,
      };
      this.axios
        .post("/usuarios/login", data)
        .then((response) => {
          self.$toast.add({
            severity: "success",
            summary: "Sucesso!!",
            detail: "Bem vindo!!",
            life: 3000,
          });
          console.log(response);

          localStorage.setItem("token", response.data.token);
          localStorage.setItem("user", JSON.stringify(response.data.user));
          this.$router.push("/dashboard");

        })
        .catch(() => {
          self.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Usuário ou senha inválida!",
            life: 3000,
          });
        });
    },
  },
};
</script>

<style scoped>
.pi-eye {
  transform: scale(1.6);
  margin-right: 1rem;
}

.pi-eye-slash {
  transform: scale(1.6);
  margin-right: 1rem;
}
</style>